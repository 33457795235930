<template>
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top"
            v-if="dialogStatus !== 'inversion'">
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="线索名称" prop="enterpriseName">
                        <el-input v-model="ruleForm.enterpriseName" placeholder="请输入"
                            :disabled="dialogStatus == 'view'"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="租户类型" prop="tenantType">
                        <el-select v-model="ruleForm.tenantType" filterable placeholder="请选择" style="width: 310px;"
                            :disabled="dialogStatus == 'view'">
                            <el-option v-for="item in userTypeOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="联系人" prop="contacts">
                        <el-input v-model="ruleForm.contacts" placeholder="请输入"
                            :disabled="dialogStatus == 'view'"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="联系电话" prop="contactNumber">
                        <el-input v-model="ruleForm.contactNumber" placeholder="请输入"
                            :disabled="dialogStatus == 'view'"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="来源" prop="source">
                        <el-select v-model="ruleForm.source" filterable placeholder="请选择" style="width: 310px;"
                            :disabled="dialogStatus == 'view'">
                            <el-option v-for="item in userSourceTypeOptions" :key="item.value" :label="item.label"
                                :value="item.label">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="地址" prop="address">
                        <el-input v-model="ruleForm.address" placeholder="请输入"
                            :disabled="dialogStatus == 'view'"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="邮箱" prop="email">
                        <el-input v-model="ruleForm.email" placeholder="请输入"
                            :disabled="dialogStatus == 'view'"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item align="right" style="margin-top: 60px;">
                <el-button type="plain" @click="clickStep('close')">取消</el-button>
                <el-button type="primary" @click="clickStep('save')" v-if="dialogStatus !== 'view'">保存</el-button>
            </el-form-item>
        </el-form>

        <el-form :model="ruleForm1" :rules="rules1" ref="ruleForm1" label-position="top" v-else>
            <el-row :gutter="48">
                <el-col :span="12">
                    <el-form-item label="接收人" prop="receivePerson">
                        <el-select v-model="ruleForm1.receivePerson" filterable value-key="id" placeholder="请选择"
                            style="width: 310px;">
                            <el-option v-for="item in personTypeOptions" :key="item.value.id" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item align="right" style="margin-top: 58px;">
                <el-button type="plain" @click="clickStep('close')">取消</el-button>
                <el-button type="primary" @click="clickStep('inversionSave')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import {
    addLeadsList,
    editLeadsList,
    getLeadsDetail,
    getSalePersonList,
    inversionCustomer
} from "@/api/ruge/gsPark/customerService/customerRelaManagement";
export default {
    props: {
        dialogStatus: {
            type: String,
            required: true,
        },
        id: {
            required: false,
        },
    },
    data() {
        return {
            ruleForm: {
                enterpriseName: '', // 线索名称
                tenantType: '', //租户类型
                contacts: '', //联系人
                contactNumber: '', //联系电话
                source: '', //客户来源
                address: '', // 地址
                email: '', //邮箱
            },
            rules: {
                enterpriseName: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                tenantType: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                contacts: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
                contactNumber: [
                    { required: true, message: '必填项', trigger: 'blur' },
                    { pattern: /^1[0-9]{10}$/, message: '手机号码格式不正确', trigger: 'blur' }
                ],
                source: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
            },
            ruleForm1: {
                receivePerson: ''
            },
            rules1: {
                receivePerson: [
                    { required: true, message: '必填项', trigger: 'change' }
                ]
            },
            personTypeOptions: [],
            userSourceTypeOptions: [
                {
                    value: '1',
                    label: '中介渠道'
                }, {
                    value: '2',
                    label: '全民营销'
                }, {
                    value: '3',
                    label: '自拓'
                }
            ],
            userTypeOptions: [{
                value: 1,
                label: '个人'
            }, {
                value: 2,
                label: '企业'
            }],
        }
    },
    methods: {
        clickStep(type) {
            if (type == 'close') {
                this.$emit("close", false);
            } else if (type == 'save') {
                console.log(this.currentState, '状态');
                console.log(this.id, 'id');
                let statusDialog = this.currentState == 'add' ? true : false
                console.log(statusDialog,'statusDialog');
                let projectVOS = {}
                projectVOS = {
                    tenantType: this.ruleForm.tenantType == 1 ? "个人" : "企业",
                    enterpriseName: this.ruleForm.enterpriseName,
                    contacts: this.ruleForm.contacts,
                    contactNumber: this.ruleForm.contactNumber,
                    address: this.ruleForm.address,
                    email: this.ruleForm.email,
                    source: this.ruleForm.source,
                    id: this.id
                }
                console.log(projectVOS, 'projectVOS');
                this.$refs["ruleForm"].validate((valid) => {
                    if (valid) {
                        if (statusDialog) {
                            addLeadsList(projectVOS).then(res => {
                                if (res.code == 200) {
                                    this.$message({
                                        type: 'success',
                                        message: '新增操作成功!'
                                    });
                                    this.$emit("close", true);
                                } else if (res.code == 500) {
                                    this.$message({
                                        message: res.msg,
                                        type: 'error'
                                    });
                                }
                            })
                        } else if (!statusDialog) {
                            editLeadsList(projectVOS).then(res => {
                                if (res.code == 200) {
                                    this.$message({
                                        type: 'success',
                                        message: '编辑操作成功!'
                                    });
                                    this.$emit("close", true);
                                } else if (res.code == 500) {
                                    this.$message({
                                        message: res.msg,
                                        type: 'error'
                                    });
                                }
                            })
                        }

                    }
                })
            } else if (type == "inversionSave") {
                this.$refs["ruleForm1"].validate((valid) => {
                    if (valid) {
                        inversionCustomer({
                            id: this.id,
                            employeeName: this.ruleForm1.receivePerson.employeeName
                        }).then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: '转化成功!'
                                });
                                this.$emit("close", true);
                            } else if (res.code == 500) {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                });
                            }
                        })
                    }
                })
            }
        },
        getCustomerLeadsDetail(id) {
            getLeadsDetail({
                id
            }).then(res => {
                console.log(res, '222');
                if (res) {
                    this.ruleForm.enterpriseName = res.data.enterpriseName
                    this.ruleForm.tenantType = res.data.tenantType == "个人" ? 1 : 2
                    this.ruleForm.contacts = res.data.contacts
                    this.ruleForm.contactNumber = res.data.contactNumber
                    this.ruleForm.address = res.data.address
                    this.ruleForm.email = res.data.email
                    this.ruleForm.source = res.data.source
                }
            })
        },
        getpersonList() {
            getSalePersonList().then(res => {
                res.data.map((item) => {
                    this.personTypeOptions.push({
                        value: item,
                        label: item.employeeName,
                    })
                })
            })
        },
    },
    watch: {
        id: {
            handler(id) {
                if (!id) return;
                this.getCustomerLeadsDetail(id);
            },
            immediate: true,
        },
        dialogStatus: {
            handler(val) {
                this.currentState = val;
                if (val == 'inversion') {
                    this.getpersonList()
                }
            },
            immediate: true,
        },
    }
}

</script>
