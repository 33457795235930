<template>
    <div class="app-container">
        <div class="filter-line">
            <span>
                <el-button type="primary" icon="el-icon-plus" @click="addCustomer">新建线索</el-button>
                <el-button icon="el-icon-download" @click="exportHandler">{{ this.$t("commons.export") }}</el-button>
                <el-button icon="el-icon-upload2" @click="uploadModel = true">{{ this.$t("commons.import")
                    }}</el-button>
                <el-button icon="el-icon-upload" @click="downCustomerLeadsTemplate">导入模板</el-button>
            </span>
            <span class="right_btm">
                <div class="customerbtm" :class="customerbtmType == '1' ? 'activeStyle' : ''" @click="changeType(1)">
                    全部线索</div>
                <div class="customerbtm" :class="customerbtmType == '2' ? 'activeStyle' : ''" @click="changeType(2)">
                    我的线索</div>
                <div class="customerbtm" :class="customerbtmType == '3' ? 'activeStyle' : ''" @click="changeType(3)">
                    下属线索</div>
            </span>
        </div>
        <div>
            <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler"></finalTable>
        </div>
        <el-dialog :visible.sync="dialogVisible" :before-close="handleClose" width="728px">
            <template slot="title">
                <span>{{ customerData.dialogTitle }}</span>
            </template>
            <customerDialog @close="handleClose" v-if="dialogVisible" :id="customerData.id"
                :dialogStatus="customerData.dialogStatus">
            </customerDialog>
        </el-dialog>
        <!--导入文件-->
        <el-dialog :visible.sync="uploadModel" width="40%">
            <template slot="title">
                <title-icon />{{ $t("commons.uploadFile") }}
            </template>
            <div slot="footer">
                <upload :ulType="uploadType" :excelType="excelType" :rootPath="rootPath" @success="uploadSuccess"
                    @fail="uploadFail"></upload>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import finalTable from "@/components/FinalTable";
import customerDialog from "./customerDialog.vue"
import {
    getLeadsList,
    deleteCustomerLeads,
    customerListExport,
    cancelCustomerStatus,
    exportCustomerList,
    switchConnect
} from "@/api/ruge/gsPark/customerService/customerRelaManagement";
import {

    downCustomerLeadsTemplate
} from "@/api/business/base/tenant/materiel";
import { mapGetters } from "vuex";
import { permissionCheck } from "@/utils/permissionCheck.js"
import { envInfo } from "@/constants/envInfo";
import Upload from "@/components/Upload";
export default {
    components: {
        finalTable,
        customerDialog,
        Upload
    },
    data() {
        return {
            uploadModel: false,
            uploadType: "ExcelImport",
            rootPath: envInfo.bgApp.personnelmanagePath,
            excelType: "ruge.customerLeads",
            customerbtmType: '1',
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: true,
                },
                header: [
                    { prop: "enterpriseName", label: "线索名称", width: "" },
                    { prop: "creationDate", label: "创建时间", width: "" },
                    { prop: "contacts", label: "联系人", width: "" },
                    { prop: "contactNumber", label: "联系电话", width: "" },
                    { prop: "employeeName", label: "销售人员", width: "" },
                    { prop: "isConnect", label: "是否已接通电话", width: "" },
                    // { prop: "roomStatus", label: "招商状态", width: "" },
                    { prop: "operation", label: "操作", width: "200" },
                ],
                tableData: [],
                // 搜索行配置
                searchLineConfig: {
                    enterpriseName: {
                        type: "input",
                        label: "客户企业姓名",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入客户企业姓名",
                        prefixIcon: "el-icon-search",
                    },
                    contacts: {
                        type: "input",
                        label: "联系人",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入联系人",
                        prefixIcon: "el-icon-search",
                    },
                    contactNumber: {
                        type: "input",
                        label: "联系电话",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入联系电话",
                        prefixIcon: "el-icon-search",
                    },
                    employeeName: {
                        type: "input",
                        label: "销售人员",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入销售人员",
                        prefixIcon: "el-icon-search",
                    },
                    isConnect: {
                        type: "selector",
                        label: "是否已接通电话",
                        actionType: "goSearch",
                        value: "",
                        placeholder: "请选择是否已开发票",
                        optionList: [
                            {
                                label: "是",
                                value: '1',
                            },
                            {
                                label: "否",
                                value: '0',
                            },
                        ],
                    },
                    // roomStatus: {
                    //     type: "selector",
                    //     label: "招商状态",
                    //     value: "",
                    //     optionList: [
                    //         {
                    //             label: "已租",
                    //             value: 1,
                    //         },
                    //         {
                    //             label: "在谈",
                    //             value: 2,
                    //         },
                    //         {
                    //             label: "预定",
                    //             value: 3,
                    //         },
                    //         {
                    //             label: "取消",
                    //             value: 4,
                    //         },
                    //         {
                    //             label: "其他",
                    //             value: 5,
                    //         },
                    //     ],
                    //     actionType: "goSearch",
                    // },
                },
                // 表格内容配置
                detailConfig: {
                    operation: {
                        type: "icon",
                        iconList: [
                            {
                                actionType: "iconClick",
                                eventName: "view",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "查看",
                            },
                            {
                                actionType: "iconClick",
                                eventName: "edit",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "编辑",
                            },
                            {
                                actionType: "iconClick",
                                eventName: "delete",
                                fontSize: "14px",
                                color: "#E8331C",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "删除",
                                showProp: "statusKey",
                                showValue: true,
                            },
                            {
                                actionType: "iconClick",
                                eventName: "inversion",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "转化客户",
                            },
                        ],
                    },
                    isConnect: {
                        type: "switch",
                        actionType: "switchEvent",
                    },
                },
                // 高级搜索配置
                advanceFilterConfig: {},
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },
            },
            dialogVisible: false,
            customerData: {
                dialogStatus: 'add',
                dialogTitle: "新建客户",
            },
            personnelObj: {
                tableLoading: false,
                listQuery: {
                    current: 1,
                    rowCount: 10,
                    type: 2
                }
            }
        }
    },
    created() {
        this.getTableList()
        console.log(permissionCheck(), 'permissionCheck');
    },
    computed: {
        ...mapGetters(["havePermissions"]),
    },
    methods: {
        getTableList() {
            this.personnelObj.tableLoading = true;
            const params = { ...this.personnelObj.listQuery };
            getLeadsList(params).then(res => {
                if (res.code == 200) {
                    const hasDeletePermision = this.havePermissions.includes('gcrm$Service$tenantCrmManagement$deleteCustomerInfo');
                    this.setOperationWidth(hasDeletePermision)
                    res.data.rows.forEach((item) => {
                        const statusMap = {
                            1: '已租',
                            2: '在谈',
                            3: '预定',
                            4: '取消',
                            0: '其他'
                        };
                        if (item.roomStatus in statusMap) {
                            item.roomStatus = statusMap[item.roomStatus];
                        } else {
                            item.roomStatus = '未知状态';
                        }
                        this.$set(item, 'statusKey', hasDeletePermision)
                        item.isConnect = item.isConnect === 1 ? "ENABLE" : "DISABLE";
                    });
                    this.dataset.pageVO.total = res.data.total;
                    this.dataset.tableData = res.data.rows;
                } else if (res.code == 424) {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    });
                } else if (res.code == 500) {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    });
                    this.dataset.tableData = []
                }
            })
                .finally(() => {
                    this.personnelObj.tableLoading = false;
                })
        },
        uploadSuccess() {
            this.uploadModel = false;
            // this.$message({
            //   type: "success",
            //   message: this.$t("message.uploadSuccess"),
            // });
        },
        uploadFail() {
            this.$message({ type: "error", message: this.$t("message.uploadFial") });
        },
        downCustomerLeadsTemplate() {
            downCustomerLeadsTemplate();
        },
        setOperationWidth(hasDeletePermision) {
            this.dataset.header.forEach(item => {
                if (item.prop === 'operation') {
                    item.width = hasDeletePermision ? '200' : '140'
                }
            })
        },
        tableEventHandler(datas) {
            if (datas.type === "goSearch") {
                this.personnelObj.listQuery = {
                    ...this.personnelObj.listQuery,
                    ...datas.params,
                };
                this.personnelObj.listQuery.current = 1;
                this.dataset.pageVO.current = 1;
                this.getTableList();
            } else if (datas.type === "paginationChange") {
                this.personnelObj.listQuery.current = datas.params.current.page;
                this.personnelObj.listQuery.rowCount = datas.params.current.limit;
                this.getTableList();
            } else if (datas.type === "iconClick") {
                switch (datas.eventName) {
                    case "view":
                        this.singleViewHandler(datas.row)
                        break;
                    case "delete":
                        this.singleDeleteHandler(datas.row);
                        break;
                    case "edit":
                        this.editCustomerLeads(datas.row)
                        break;
                    case "inversion":
                        this.inversionCustomerLeads(datas.row)
                        break;
                }
            } else if (datas.type === "switchEvent") {
                this.updateEnableStatus(datas.row);
            }
        },
        editCustomerLeads(row) {
            this.dialogVisible = true
            this.customerData.id = row.id;
            this.customerData.dialogStatus = "edit";
            this.customerData.dialogTitle = "编辑线索";
        },
        inversionCustomerLeads(row) {
            this.dialogVisible = true
            this.customerData.id = row.id;
            this.customerData.dialogStatus = "inversion";
            this.customerData.dialogTitle = "转化客户";
        },
        updateEnableStatus({ id, isConnect }) {
            console.log(id, isConnect,'data');
            const emuMap = {
                ENABLE: "1",
                DISABLE: "0",
            };
            switchConnect({
                id,
                isConnect: emuMap[isConnect],

            }).then(res => {
                if (res) {
                    this.$message.success("修改状态成功！");
                    this.getTableList();
                }
            })
        },
        // 导出
        exportHandler() {
            exportCustomerList(this.personnelObj.listQuery).then((taskId) => {
                this.$message({
                    type: "success",
                    message: this.$t("message.operationSuccess"),
                });
                let exportObj = {
                    taskId,
                    taskName: "线索客户",
                    taskStatus: 0,
                    rootPath: "gcrm",
                };
                this.$store.dispatch("PushExportNotice", exportObj);
            });
        },
        singleDeleteHandler(row) {
            this.$confirm(
                this.$t("message.deleteConfirm"),
                this.$t("commons.warning"),
                {
                    confirmButtonText: this.$t("commons.confirm"),
                    cancelButtonText: this.$t("commons.cancel"),
                    type: "warning",
                }
            ).then(() => {
                console.log(row, 'row');
                deleteCustomerLeads({
                    id: row.id
                }).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getTableList()
                    } else if (res.code == 424) {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    } else if (res.code == 500) {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            })
        },
        singleViewHandler(row) {
            // this.dialogVisible = true
            // this.customerData.id = row.id;
            // this.customerData.dialogStatus = "view";
            // this.customerData.dialogTitle = "查看线索";
            this.$router.push({
                path: "/gsPark/customerRelaManagement/customerLeads/customerLeadsView",
                query: { id: row.id },
            });
        },
        changeType(type) {
            if (type == '1') {
                this.customerbtmType = '1'
                this.personnelObj.listQuery.type = 2
                this.getTableList()
            } else if (type == '2') {
                this.customerbtmType = '2'
                this.personnelObj.listQuery.type = 1
                this.getTableList()
            } else if (type == '3') {
                this.customerbtmType = '3'
                this.personnelObj.listQuery.type = 3
                this.getTableList()
            }
        },
        handleClose(freshFlag) {
            this.dialogVisible = false
            freshFlag && this.getTableList();
        },
        addCustomer() {
            this.dialogVisible = true
            this.customerData.id = null;
            this.customerData.dialogStatus = "add";
            this.customerData.dialogTitle = "新建线索";
        }
    },
    watch: {
        personnelObj: {
            handler(val) {
                this.$refs.finalTableRef.loadingToggle(val.tableLoading);
            },
            deep: true,
        },
    }
}
</script>
<style lang="less" scoped>
.filter-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
}

.right_btm {
    display: flex;

    .customerbtm {
        width: 92px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        color: #2F3941;
        border: 1px solid #E4E7ED;
        text-align: center;
        border-radius: 4px 4px 4px 4px;
        background: #FFFFFF;
        cursor: pointer;
        margin-right: 16px;
    }

    .activeStyle {
        color: #1A4CEC !important;
        background: #F3F5FA !important;
        border: 1px solid #0938D0 !important;
    }
}
</style>