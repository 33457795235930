var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.dialogStatus !== "inversion"
        ? _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "线索名称", prop: "enterpriseName" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入",
                              disabled: _vm.dialogStatus == "view",
                            },
                            model: {
                              value: _vm.ruleForm.enterpriseName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "enterpriseName", $$v)
                              },
                              expression: "ruleForm.enterpriseName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "租户类型", prop: "tenantType" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "310px" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择",
                                disabled: _vm.dialogStatus == "view",
                              },
                              model: {
                                value: _vm.ruleForm.tenantType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "tenantType", $$v)
                                },
                                expression: "ruleForm.tenantType",
                              },
                            },
                            _vm._l(_vm.userTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人", prop: "contacts" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入",
                              disabled: _vm.dialogStatus == "view",
                            },
                            model: {
                              value: _vm.ruleForm.contacts,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "contacts", $$v)
                              },
                              expression: "ruleForm.contacts",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系电话", prop: "contactNumber" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入",
                              disabled: _vm.dialogStatus == "view",
                            },
                            model: {
                              value: _vm.ruleForm.contactNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "contactNumber", $$v)
                              },
                              expression: "ruleForm.contactNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "来源", prop: "source" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "310px" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择",
                                disabled: _vm.dialogStatus == "view",
                              },
                              model: {
                                value: _vm.ruleForm.source,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "source", $$v)
                                },
                                expression: "ruleForm.source",
                              },
                            },
                            _vm._l(_vm.userSourceTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.label },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址", prop: "address" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入",
                              disabled: _vm.dialogStatus == "view",
                            },
                            model: {
                              value: _vm.ruleForm.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "address", $$v)
                              },
                              expression: "ruleForm.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "邮箱", prop: "email" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入",
                              disabled: _vm.dialogStatus == "view",
                            },
                            model: {
                              value: _vm.ruleForm.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "email", $$v)
                              },
                              expression: "ruleForm.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-top": "60px" },
                  attrs: { align: "right" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "plain" },
                      on: {
                        click: function ($event) {
                          return _vm.clickStep("close")
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm.dialogStatus !== "view"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.clickStep("save")
                            },
                          },
                        },
                        [_vm._v("保存")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "el-form",
            {
              ref: "ruleForm1",
              attrs: {
                model: _vm.ruleForm1,
                rules: _vm.rules1,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "接收人", prop: "receivePerson" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "310px" },
                              attrs: {
                                filterable: "",
                                "value-key": "id",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.ruleForm1.receivePerson,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm1, "receivePerson", $$v)
                                },
                                expression: "ruleForm1.receivePerson",
                              },
                            },
                            _vm._l(_vm.personTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value.id,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-top": "58px" },
                  attrs: { align: "right" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "plain" },
                      on: {
                        click: function ($event) {
                          return _vm.clickStep("close")
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.clickStep("inversionSave")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }